import { DEFAULT_META_DESCRIPTION, DEFAULT_META_IMAGE, DEFAULT_META_TITLE } from '@endaoment-frontend/constants';
import { routes } from '@endaoment-frontend/routes';
import type { MetaData } from '@endaoment-frontend/types';

export const defaultPage: MetaData = {
  title: DEFAULT_META_TITLE,
  description: DEFAULT_META_DESCRIPTION,
  image: DEFAULT_META_IMAGE,
  url: routes.marketing.home({ useFullUrl: true }),
};

export const homePage: MetaData = {
  title: 'Charitable Giving & Donor-Advised Fund Platform | Donate Stock, Cash & Crypto | Endaoment',
  description:
    "We're a tax-exempt community foundation focused on social impact. Start or migrate a fund today or donate to non-profits with cash, stocks or crypto.",
  header: 'Modern Giving',
  keywords: 'Donor-advised Fund, Donor-advised Funds, Charitable Giving, Donate Crypto, Donate Stock, Crypto Donation',
  url: routes.marketing.home({ useFullUrl: true }),
};

export const learnPage: MetaData = {
  title: 'The Community Foundation Built For The Internet | Endaoment',
  description:
    'Endaoment offers an onchain platform for making charitable donations with a range of assets. Blockchain transparency makes each transaction publicly verifiable.',
  header: 'A community foundation built for the internet.',
  keywords: 'Community foundation',
  url: routes.marketing.learn({ useFullUrl: true }),
};

export const aboutPage: MetaData = {
  title: 'The Philanthropic Institution of the Future | Endaoment',
  description:
    "Our donations platform is built onchain & powered by smart contracts, a digital agreement facilitated on a blockchain. We've created a better way to give.",
  header: 'The Philanthropic Institution of the Future',
  url: routes.marketing.about({ useFullUrl: true }),
};

export const otcPage: MetaData = {
  title: 'Donate Eth, Bitcoin & 1000s of Cryptocurrencies to Charity | Endaoment',
  description:
    'Looking to make a donation to an Endaoment fund “Over-the-Counter”? We accept over 1,000 different cryptocurrencies in-app and NFTs over-the-counter.',
  header: 'Donate Crypto to Charity',
  keywords: 'Donate Eth, Donate Bitcoin, Donate Cryptocurrencies, Donate cryptocurrencies to charity',
  url: routes.marketing.otc({ useFullUrl: true }),
};

export const advisorsPage: MetaData = {
  title: 'Self-Directed Donor Advised Funds | For Advisors | Endaoment',
  description:
    'A modern suite of giving tools for clients of all kinds with a wide selection of self-service complex donation methods. Industry-low fees. Find out more.',
  header: 'A modern suite of giving tools for clients of all kinds',
  keywords: 'Self Directed Donor Advised Funds',
  image: 'https://storage.googleapis.com/endaoment-static/endaoment-for-advisors-og-image.png',
  url: routes.marketing.advisors({ useFullUrl: true }),
};

export const impact2022Page: MetaData = {
  title: 'Charitable Giving Statistics | Philanthropy Report | Endaoment',
  description:
    "Charitable giving statistics and highlights from Endaoment's second year of decentralized philanthropy.",
  header: 'Impact Report',
  keywords: 'Charitable Giving Statistics',
  url: routes.marketing.impact({ useFullUrl: true }),
  image: 'https://endaoment.org/images/impact/impact-og-image.png',
};

export const impact2023Page: MetaData = {
  title: 'Charitable Giving Statistics | Philanthropy Report | Endaoment',
  description: "Charitable giving statistics and highlights from Endaoment's third year of decentralized philanthropy.",
  header: 'Impact Report',
  keywords: 'Charitable Giving Statistics',
  url: routes.marketing.impact({ useFullUrl: true }),
  image: 'https://storage.googleapis.com/endaoment-static/impact-report/impact-og-image.png',
};

export const impact2024Page: MetaData = {
  title: 'Charitable Giving Statistics | Philanthropy Report | Endaoment',
  description:
    "Charitable giving statistics and highlights from Endaoment's fourth year of decentralized philanthropy.",
  header: 'Impact Report',
  keywords: 'Charitable Giving Statistics',
  url: routes.marketing.impact({ useFullUrl: true }),
  image: 'https://storage.googleapis.com/endaoment-static/impact-report/2024/impact-og-image.png',
};

export const donorsPage: MetaData = {
  title: 'Donor Advised Fund App | DAFs | Onchain Giving Platform | Endaoment',
  description:
    'Our platform is powered by smart contracts, a new kind of digital agreement on a blockchain. This unlocks powerful benefits for philanthropic giving.',
  header: 'Donate Stocks or Fiat',
  keywords: 'Donor Advised Fund App, DAFs, Onchain Giving',
  url: routes.marketing.donors({ useFullUrl: true }),
};

export const orgsPage: MetaData = {
  title: 'Accepting Crypto Donations for Nonprofits | Receive Crypto | Endaoment',
  description:
    'Accept credit, debit, ACH, Apple & Google Pay, stocks & cryptocurrencies such as Ethereum & Bitcoin. Claim a nonprofit to accept traditional & digital assets.',
  header: 'Accept credit, debit, ACH, Apple Pay, Google Pay, stocks, and—of course—crypto',
  keywords: 'accept crypto payments on website, accept ethereum donations, accepting crypto donations',
  url: routes.marketing.orgs({ useFullUrl: true }),
};

export const calculatorPage: MetaData = {
  title: 'Charitable Donations Tax Deduction Calculator 2024 | Endaoment',
  description:
    'Our donations calculator gathers information about your current tax situation and expected charitable giving to estimate tax savings as an individual filer.',
  header: 'Charitable Donations Tax Deduction Calculator',
  keywords:
    'Charitable Donations Calculator, Donations Tax Calculator, Donations Tax Deduction Calculator, 2024 related',
  url: routes.marketing.donationCalculator({ useFullUrl: true }),
};
